
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import { Inhibitor } from "../../models/reports/inhibitor";
import { formatDecimal, formatFinancialAmounts } from "../../utils/format";

export default Vue.extend({
  name: "FactorVIIIUsage",
  data: () => ({
    dataTable: [] as Inhibitor[],
    isLoading: true,
    formatDecimal,
    formatFinancialAmounts,
  }),
  computed: {
    selectedSurvey() {
      return this.$store.getters["agsreport/getYearSelected"];
    },
    rowLabel() {
      return [
        {
          text: i18n.t(
            "reports.agsreports.FactorVIIIUsage.FVIII_per_capita_mean"
          ),
          value: "mean",
          formatDecimal: 6,
        },
        {
          text: i18n.t(
            "reports.agsreports.FactorVIIIUsage.FVIII_per_capita_standard_deviation"
          ),
          value: "standardDeviation",
          formatDecimal: 6,
        },
        {
          text: i18n.t(
            "reports.agsreports.FactorVIIIUsage.FVIII_per_capita_median"
          ),
          value: "median",
          formatDecimal: 6,
        },
        {
          text: i18n.t(
            "reports.agsreports.FactorVIIIUsage.25th_percentile_of_FVIII_per_capita"
          ),
          value: "percentile25",
          formatDecimal: 6,
        },
        {
          text: i18n.t(
            "reports.agsreports.FactorVIIIUsage.75th_percentile_of_FVIII_per_capita"
          ),
          value: "percentile75",
          formatDecimal: 6,
        },
        {
          text: i18n.t(
            "reports.agsreports.FactorVIIIUsage.Interquartile_range_global_per_capita_FVIII_usage"
          ),
          value: "iqr",
          formatDecimal: 6,
        },
        {
          text: i18n.t(
            "reports.agsreports.FactorVIIIUsage.Total_consumption_of_FVIII_concentrates"
          ),
          value: "totalConsumption",
          formatFinancialAmounts: true,
        },
        {
          text: i18n.t(
            "reports.agsreports.FactorVIIIUsage.Number_of_countries"
          ),
          value: "countries",
          formatFinancialAmounts: true,
        },
      ];
    },
    headers() {
      return [
        {
          text: i18n.t("reports.agsreports.yearselected"),
          value: "surveyYear",
        },
        {
          text: i18n.t("reports.agsreports.FactorVIIIUsage.demographics"),
          value: "demographics",
        },
        {
          text: i18n.t("reports.agsreports.FactorVIIIUsage.Total"),
          value: "totalConsumption",
        },
      ];
    },
  },
  methods: {
    getData(year: number) {
      this.isLoading = true;
      axios
        .get(`Report/WFH/TreatmentStatistics/1`, { params: { year: year } })
        .then((res) => {
          this.dataTable = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(
          `Report/wfh/TreatmentStatistics/1/export?year=${this.selectedSurvey.year}`,
          { responseType: "blob" }
        )
        .then((res) => {
          const fileName = `${i18n.t(
            "navbar.menu.reportsub.ags_reports"
          )} - ${i18n.t("reports.agsreports.FactorVIIIUsage.title")}`;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "aplication/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.selectedSurvey.year) {
      this.getData(this.selectedSurvey.year);
    }
  },
  watch: {
    selectedSurvey() {
      this.getData(this.selectedSurvey.year);
    },
  },
});
